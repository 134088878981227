import React from 'react';
import Webcam from 'react-webcam';

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App" style={{border:'2px solid black' , padding:'60px'}}>
      <div>
          salom
      <Webcam />
    </div>
    </div>
  );
}

export default App;
